@use "../../scss/" as *;

.movie-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    margin-bottom: 3rem;

    @include tablet {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
    &__loadmore{
        text-align: center;
    }
}
.movie-search{
    position: relative;
    width: 100%;
    max-width: 500px;
    input{
        width: 100%;
        padding-right: 8rem;
    }
    button{
        position: absolute;
        right: 0;
        top: 2px;
        @include mobile{
            right: 1px;
            top:1px;
        }
    }
    
}

.dropdown{
    @include mobile{
        left: 0;
    }
    position: absolute;
    top: 3rem;
    z-index: 99;
    display: none;
    width: 100%;
    max-width: 500px;
    // padding: 16px 0px;
    background: #202020;
    border-radius: 8px;
    .dropdown-row{
        border-bottom: 0.2px solid #303030;
        padding: 8px 5px;
        
        &:hover{ 
            background: #2c2c2e;
            cursor: pointer;
            border-radius: 8px;
        }
        &:last-child{
            border-bottom: none;
            padding-bottom: 12px;
        }
        &:first-child{
            padding-top:12px;
        }
    }
    &.Showdropdown{
        display: block;
    }
 }
 select{
    background: #141414;
    cursor: pointer;
    border: 1px solid #fff;
    color: #fff;
    font-size: 1rem;
    padding: 0.5rem 1.3rem;
    font-weight: 600;
    letter-spacing: .06vw;
    margin-top: 1rem;
    // padding: 5px;
    border-width: 2px;
    max-width: 200px;
    width: 100%;
    font-family: $font-family;
    option{
        @include mobile{
            line-height: 1;
        }
        }
       
}

// .movie-search:focus + .dropdown
// {
//     display: block;
// }
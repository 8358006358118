@use '../../scss/' as *;

.banner{
    height: 50vh;
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include overlay();
    }

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100px;
        background-image: linear-gradient(to top, $body-bg, rgba($black, 0));
    }
}
.movie-content{
    @include flex(flex-start, flex-start);
    max-width: 1260px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -200px;
    position: relative;
    padding: 0 2rem;

    &__poster {
        flex: 1;

        @include mobile {
            display: none;
        }

        &__img {
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: $border-radius;
            padding-top: 165%;
        }
    }
    &__infor{
        width: 70%;
        padding-left: 2rem;
        position: relative;
        @include mobile{
            width: 100%;
            padding-left: 0;
        }
        & > * {
            margin-bottom: 2rem;
        }
        .title{
            font-size: 4rem;
            line-height: 1;
        }
        .genres{
            & > * ~ * {
                margin-left: .5rem;
            }
            &__item{
                padding: 0.5rem 1.5rem;
                border: 2px solid $white;
                border-radius: $border-radius;
                font-size: 0.8rem;
                font-weight: 600;
                background-color: $body-bg;
            }
        }
        .module-btn{
            a.facebook-share-button {
                margin-top: 1rem;
                display: inline-block;
                background-color: #3b5998;
                // color: #fff;
                // padding: 8px 16px;
                box-shadow: 0px 0px 7px 8px rgba(21, 0, 255, 0.302);
                transition: box-shadow 0.3s ease;
                text-decoration: none;
                margin-left: 0.5rem;
                line-height: normal;
                &:hover{
                    box-shadow: 0px 0px 7px 15px rgba(21, 0, 255, 0.302);
                    color: $white;
                }
              }
        }
        .release-date ,.runtime ,.rating{
            h3{
                max-width: 8rem;
                width: 100%;
                margin-right: 0.5rem;
                font-family: $font-family;
                font-size: 1.17em;
                font-weight: bold;
            }
            .star{
                margin-left: 0.5rem;
                
            }
        }
    }
}
.casts{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
    gap: 10px;

    &__item{
        &__img{
            padding-top: 160px;
            background-size: cover;
            margin-top: .5rem;

        }
        &__name{
            font-size:.8rem;
        }
    }
}
.video{
    margin-bottom: 3rem;

    &__title{
        margin-bottom: 1.5rem;
        
    }
    .player-wrapper {
        position: relative;
        padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
       
      }
      .react-player {
        position: absolute;
        top: 0;
        left: 0;
      }

}
.fb-comments{
    background-color: $white !important;
    iframe{
        background-color: $white !important;
    }
}
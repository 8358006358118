@use "../../scss/" as *;

.footer {
    position: relative;
    padding: 6rem 2rem;
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    &__copyRight{
        margin-bottom: 15px;
        margin-top:2rem;
        text-align: center;
        color: rgba(255, 255, 255, 0.7);
    }

    &__content {
        max-width: 1000px;

        &__logo {
            @include flex(center, center);
            margin-bottom: 2rem;
        }

        &__menus {
            display: grid;
            grid-template-columns: repeat(3, 1fr);//grid container sẽ được chia thành 3 cột bằng nhau, mỗi cột chiếm 1/3 không gian của grid container

            @include mobile {
                grid-template-columns: repeat(2, 1fr);
            }
        }

        &__menu {
            @include flex(flex-start, flex-start);
            flex-direction: column;
            margin-top: 1rem;
            color: rgba(255, 255, 255, 0.7);
            font-size: 1rem;
            font-weight: 600;

            & > * ~ * {// áp dụng cho tất cả các phần tử HTML đứng sau phần tử đầu tiên có phần tử con, cùng cấp với các phần tử con của phần tử đó
                margin-top: 1rem;
            }
        }
    }
}
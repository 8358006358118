@use '../../scss/' as *;
.topbtn{
    // position: absolute;
    // right: 0;
    // margin-top: -5rem;
   
    display: none;
    position: fixed;
    right: 2rem;
    bottom: 100px;
    z-index: 900;
    // padding: 1rem;
    border: none;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    background-color: $main-color;
    color: $white;
    cursor: pointer;
    font-family: $font-family;
    box-shadow: 0px 0px 7px 8px #ff00004d;
    transition: box-shadow 0.3s ease;
    font-size: 2.5rem;
    font-weight: 600;
    @include mobile{
        bottom: 100px;
    }
    &:hover{
        box-shadow: 0px 0px 7px 15px #ff00004d;
    }
    &.showbtn{
        // display: block;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}